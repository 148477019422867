import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VuetifyConfirm from './vuetify-confirm/vuetifyConfirm'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0078d7',
        secondary: '#424242',
        accent: '#429ce3',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'fa',
    values: {
      activate: 'fa-play fa-fw',
      add: 'fa-plus fa-fw',
      archive: 'fa-archive',
      calendar: 'fa-calendar-day fa-fw',
      clear: 'fa-times',
      collapse: 'fa-chevron-up',
      collapseAll: 'fa-minus-square',
      comment: 'fa-comment fa-fw',
      copy: 'fa-copy',
      discard: 'fa-undo fa-fw',
      edit: 'fa-pencil-alt fa-fw',
      error: 'fa fa-times-circle fa-fw',
      expandAll: 'fa-plus-square',
      flag: 'fa-flag',
      ignore: 'fa-times fa-fw',
      important: 'fa-exclamation',
      link: 'fa-link',
      list: 'fa-list',
      logout: 'fas fa-sign-out-alt',
      manage: 'fa-cog fa-fw',
      map: 'fa-random fa-fw',
      modified: 'fa-circle fa-fw',
      publish: 'fa-upload fa-fw',
      refresh: 'refresh',
      restore: 'fa-undo',
      rightArrow: 'fa-arrow-right',
      save: 'fa-save fa-fw',
      search: 'fa-search fa-fw',
      trash: 'fa-trash-alt fa-fw',
      user: 'fa-user-circle',
      warning: 'fa-exclamation-triangle fa-fw',
      history: 'fa-history fa-fw',
      externalLink: 'fas fa-external-link-alt',
      download: 'fa-download fa-fw',
      star: 'far fa-star',
      starFill: 'fas fa-star',
      stop: 'fa-times fa-fw',
      back: 'fas fa-arrow-alt-circle-left',
      square: 'fa-solid fa-square',
      circle: 'fa-solid fa-circle',
      triangle: 'fa-solid fa-play fa-rotate-270'
    }
  }
})

Vue.use(VuetifyConfirm, { vuetify })

export default vuetify
